import React from 'react';
import HintsBox from '../HintsBox/HintsBox';

const FormErrorHintsBox = ({ errors }) => {
  const messages = [];
  if (errors) {
    Object.entries(errors).forEach(([key, value]) => {
      // console.log(`${key}: ${value.message}`);
      messages.push(value.message);
    });
  }

  if (messages.length === 0) {
    return null;
  }

  return <HintsBox messages={messages} />;
};

export default FormErrorHintsBox;
