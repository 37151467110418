import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { useAuthStore, useAplloClientStore } from './stores';
import Authenticated from './components/Authenticated/Authenticated';
import Login from './components/Login/Login';
import { GlobalStyle } from './globalStyles';

const App = () => {
  const authState = useAuthStore(state => state.authState);
  const apolloClient = useAplloClientStore(state => state.apolloClient);

  return (
    <ApolloProvider client={apolloClient}>
      <GlobalStyle />
      <BrowserRouter>
        <>
          {authState === 'unauthenticated' ? (
            <Switch>
              <Route path='/login' component={Login} />
              <Route render={() => <Redirect to='/login' />} />
            </Switch>
          ) : (
            <Authenticated />
          )}
        </>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
