import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { substrateAddresses } from '../../../queries/substrateAddress';
import { handleFormError } from '../../../utils/handleFormError';
import FormErrorHintsBox from '../../../elements/FormErrorHintsBox/FormErrorHintsBox';

const CREATE_ADDRESS_MUTATION = gql`
  mutation createSubstrateAddress(
    $name: String!
    $coin_type: SubstrateAddressCoinType!
  ) {
    createSubstrateAddress(name: $name, coin_type: $coin_type)
  }
`;

const CreateSubstrateAddress = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const coinTypes = ['ACA', 'DOT', 'GLMR', 'KSM', 'MOVR', 'PCX', 'SDN'];

  const [createAddress, { loading }] = useMutation(CREATE_ADDRESS_MUTATION);

  async function onSubmit({ name, coin_type }) {
    try {
      const res = await createAddress({
        variables: {
          name,
          coin_type,
        },
        refetchQueries: [{ query: substrateAddresses }],
      });
      history.push(`/substrate/${res.data.createSubstrateAddress}`);
    } catch (error) {
      handleFormError(error, setError);
    }
  }

  return (
    <div>
      <h2>Create Substrate Address</h2>

      <FormErrorHintsBox errors={errors} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register('name')} />

        <select {...register('coin_type')}>
          {coinTypes.map(ct => (
            <option key={ct} value={ct}>
              {ct}
            </option>
          ))}
        </select>

        <button disabled={loading} type='submit'>
          Create
        </button>
      </form>
    </div>
  );
};

export default CreateSubstrateAddress;
