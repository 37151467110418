import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  // HttpLink,
  from,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { useAuthStore, useAplloClientStore } from './stores';

function logout() {
  const setAuthState = useAuthStore.getState().setAuthState;
  const resetClient = useAplloClientStore.getState().resetClient;

  resetClient();
  localStorage.removeItem('token');
  // TODO maybe set to some intermediate state like 'auth-error',
  // which then handles showing a toast or something and redirects
  // further to login page
  // TODO also, logout here is very similar to logout in Logout.jsx
  setAuthState('unauthenticated');
}

export function makeClient() {
  // const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_URL });
  const uploadLink = createUploadLink({ uri: process.env.REACT_APP_API_URL });

  const authLink = new ApolloLink((operation, forward) => {
    // Retrieve the authorization token from local storage.
    const token = localStorage.getItem('token');

    // Use the setContext method to set the HTTP headers.
    operation.setContext({
      headers: {
        'x-token': token ? `${token}` : '',
      },
    });

    // Call the next link in the middleware chain.
    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // console.log(JSON.stringify(graphQLErrors));
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        // console.log(
        //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        // );
        // TODO check/other errors? best to check message?
        if (message === 'not-authenticated') {
          logout();
        }
      });
    }

    // if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    // link: authLink.concat(httpLink), // Chain it with the HttpLink
    link: from([errorLink, authLink, uploadLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            // Reusable helper function to generate a field
            // policy for the Query.search field, keyed by
            // search query:
            search: relayStylePagination(['query']),
            // substrRewPerTimeUnit: {
            //   merge: false,
            // },
          },
        },
      },
    }),
  });

  return client;
}
