import { gql } from '@apollo/client';

export const manualRewardsUsd = gql`
  query manualRewardsUsd($days: Int) {
    manualRewardsUsd(days: $days) {
      id
      name
      daily_usd
      sum_usd
      sum_eur
      sum_btc
      sum_eth
    }
  }
`;
