import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  padding: 20px;
  background-color: #2b2b2b;
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    // console.log({ active, payload, label });
    const pl = payload[0];
    return (
      <TooltipContainer>
        <div>{`${label}`}</div>
        <div>{`${pl.dataKey}: ${pl.value}`}</div>
      </TooltipContainer>
    );
  }

  return null;
};

const MyAreaChart = ({ data, dataKeyX, dataKeyArea }) => (
  <ResponsiveContainer width='100%' height='100%'>
    <AreaChart
      width={800}
      height={500}
      data={data}
      margin={{
        top: 5,
        right: 20,
        left: 20,
        bottom: 5,
      }}>
      <CartesianGrid strokeDasharray='3 3' />

      {/* <Tooltip/> */}
      <Tooltip content={<CustomTooltip />} />

      <Area
        type='monotone'
        dataKey={dataKeyArea}
        fill='#f70476'
        stroke='#f70476'
      />

      <YAxis stroke='#f70476' />

      <XAxis dataKey={dataKeyX} />
    </AreaChart>
  </ResponsiveContainer>
);

export default MyAreaChart;
