import create from 'zustand';
import { makeClient } from './apolloClient';

export const useAuthStore = create(set => ({
  authState: 'authenticated',
  setAuthState: newState => set(state => ({ authState: newState })),
}));

export const useAplloClientStore = create(set => ({
  apolloClient: makeClient(),
  resetClient: () => set(state => ({ apolloClient: makeClient() })),
}));
