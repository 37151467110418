import React, { useState } from 'react';
import styled from 'styled-components';
import StyledButton from '../../styledComponents/Button';

const Button = styled(StyledButton)`
  background: ${props => (props.active ? '#f70476' : 'transparent')};
`;

const ButtonGroup = ({ buttons, initialActive }) => {
  const [activeButton, setActiveButton] = useState(initialActive);

  function onButtonClicked(i, callbackFn) {
    // console.log({ i, callbackFn });
    setActiveButton(i);
    callbackFn();
  }

  return (
    <>
      {buttons.map(({ text, onClick }, i) => (
        <Button
          onClick={() => onButtonClicked(i, onClick)}
          active={i === activeButton}
          key={i}
          name={text}>
          {text}
        </Button>
      ))}
    </>
  );
};

export default ButtonGroup;
