import {
  extractGraphqlError,
  extractValidationErrors,
} from './extractGraphQlError';

export function handleFormError(error, setError) {
  // console.log(JSON.stringify(error, 0, 2));
  // console.log(error);
  const errorCode = extractGraphqlError(error);
  // console.log(errorCode);

  if (errorCode === 'validation-error') {
    const validationErrors = extractValidationErrors(error);
    // console.log(validationErrors);

    for (let i = 0; i < validationErrors.length; i++) {
      const valErr = validationErrors[i];
      setError(valErr.path, {
        type: 'manual',
        message: valErr.message,
      });
    }
  }
  // TODO else alert unknown error?
}
