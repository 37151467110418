import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  substrateAddress,
  substrateAddressDeleteMut,
} from '../../queries/substrateAddress';
import { ActionButtonsRow } from './SubstrateAddress.styles';
import Button from '../../styledComponents/Button';
import UploadCSV from './UploadCSV';
import RewardsChart from './RewardsChart';
import RewardsList from './RewardsList';
import RewardsSummary from './RewardsSummary';
import DeleteModal from '../../elements/DeleteModal/DeleteModal';

const SubstrateAddress = () => {
  const history = useHistory();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { id } = useParams();
  const { data, loading } = useQuery(substrateAddress, {
    variables: { id },
  });
  // console.log({ data, loading });
  const [deleteAddress] = useMutation(substrateAddressDeleteMut);

  async function handleDelete() {
    setDeleteModalOpen(false);
    try {
      await deleteAddress({
        variables: { address_id: id },
        refetchQueries: ['substrateAddresses'],
      });
      history.push('/');
    } catch (error) {
      console.log(JSON.stringify(error, 0, 2));
    }
  }

  if (loading) {
    return <div>loading ...</div>;
  }

  if (!loading && !data.substrateAddress) {
    return <div>no address found</div>;
  }

  return (
    <div>
      {deleteModalOpen && (
        <DeleteModal
          isOpen={true}
          onRequestClose={() => setDeleteModalOpen(false)}
          onCancel={() => setDeleteModalOpen(false)}
          onDelete={handleDelete}
        />
      )}
      <h1>SubstrateAddress {data.substrateAddress.name}</h1>

      <ActionButtonsRow>
        <UploadCSV address_id={id} />
        <Button onClick={() => setDeleteModalOpen(true)}>Delete</Button>
      </ActionButtonsRow>

      <RewardsSummary address_id={id} />

      <h2>Charts</h2>

      <RewardsChart address_id={id} time_unit='month' chartType='bar' />
      <RewardsChart address_id={id} time_unit='day' chartType='area' />

      <RewardsList address_id={id} />
    </div>
  );
};

export default SubstrateAddress;
