import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    /* color: ${props => (props.whiteColor ? 'white' : 'black')}; */
    font-family: 'IBM Plex Mono', monospace;
    color: white;
    background-color: #131313;
    /* padding-bottom: 30px; */
  }

  a {
    color: white;

    &:visited {
      color: white;
    }

    &:hover {
      color: #f70476;
    }
  }
`;
