import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { AiFillHome, AiOutlineDollarCircle } from 'react-icons/ai';
import { BsPlusSquare, BsBarChart } from 'react-icons/bs';
import { BiCoinStack } from 'react-icons/bi';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { nexoAccounts } from '../../queries/nexoAccount';
import { celsiusAccounts } from '../../queries/celsiusAccount';
import { manualTrackBalances } from '../../queries/manualTrackBalance';
import { substrateAddresses } from '../../queries/substrateAddress';
import { Nav, SidebarWrap } from './Sidebar.styles';
import SubMenu from './SubMenu';

const Sidebar = () => {
  const { data } = useSidebarState();

  return (
    <Nav>
      <SidebarWrap>
        {data.map((item, idx) => (
          <SubMenu key={idx} item={item} />
        ))}
      </SidebarWrap>
    </Nav>
  );
};

function useSidebarState() {
  const [data, setData] = useState([]);
  const [celAccs, setCelAccs] = useState([]);
  const [nexoAccs, setNexoAccs] = useState([]);
  const [manTrackBalances, setManTrackBalances] = useState([]);
  const [subAdds, setSubAdds] = useState([]);

  const { data: celAccData } = useQuery(celsiusAccounts, {
    fetchPolicy: 'network-only',
  });
  const { data: manTrackBalancesData } = useQuery(manualTrackBalances, {
    fetchPolicy: 'network-only',
  });
  const { data: nexoAccData } = useQuery(nexoAccounts, {
    fetchPolicy: 'network-only',
  });
  const { data: subAddData, error: subAddError } = useQuery(
    substrateAddresses,
    {
      fetchPolicy: 'network-only',
    }
  );

  if (subAddError) {
    console.log({ subAddError });
  }

  useEffect(() => {
    async function run() {
      if (!celAccData) {
        return;
      }
      const newData = celAccData.celsiusAccounts.map(d => {
        return {
          title: d.name,
          path: `/celsius/${d.id}`,
          icon: <BsBarChart />,
        };
      });
      setCelAccs(newData);
    }
    run();
  }, [celAccData]);

  useEffect(() => {
    async function run() {
      if (!manTrackBalancesData) {
        return;
      }
      const newData = manTrackBalancesData.manualTrackBalances.map(d => {
        return {
          title: d.name,
          path: `/manual_track_balance/${d.id}`,
          icon: <BsBarChart />,
        };
      });
      setManTrackBalances(newData);
    }
    run();
  }, [manTrackBalancesData]);

  useEffect(() => {
    async function run() {
      if (!nexoAccData) {
        return;
      }
      const newData = nexoAccData.nexoAccounts.map(d => {
        return {
          title: d.name,
          path: `/nexo/${d.id}`,
          icon: <BsBarChart />,
        };
      });
      setNexoAccs(newData);
    }
    run();
  }, [nexoAccData]);

  useEffect(() => {
    async function run() {
      if (!subAddData) {
        return;
      }
      const newData = subAddData.substrateAddresses.map(a => {
        return {
          title: a.name,
          path: `/substrate/${a.id}`,
          icon: <BsBarChart />,
        };
      });
      setSubAdds(newData);
    }
    run();
  }, [subAddData]);

  useEffect(() => {
    async function getData() {
      let d = [];
      d.push({ title: 'Dashboard', path: '/dashboard', icon: <AiFillHome /> });

      /* CELSIUS */
      const celsiusSubNavs = [
        {
          title: 'Create',
          path: '/celsius/create',
          icon: <BsPlusSquare />,
        },
      ];
      celsiusSubNavs.push.apply(celsiusSubNavs, celAccs);
      const celsiusMenu = {
        title: 'Celsius',
        path: '#',
        icon: <BiCoinStack />,
        iconClosed: <RiArrowDownSFill />,
        iconOpen: <RiArrowUpSFill />,
        subNav: celsiusSubNavs,
      };
      d.push(celsiusMenu);

      /* MANUAL USD */
      d.push({
        title: 'Manual USD',
        path: '/manual_rew_usd',
        icon: <AiOutlineDollarCircle />,
      });

      /* MANUAL TRACK COIN */
      const manTrackBalancesSubNavs = [
        {
          title: 'Create',
          path: '/manual_track_balance/create',
          icon: <BsPlusSquare />,
        },
      ];
      manTrackBalancesSubNavs.push.apply(
        manTrackBalancesSubNavs,
        manTrackBalances
      );
      const manTrackBalanceMenu = {
        title: 'Manual Balance',
        path: '#',
        icon: <BiCoinStack />,
        iconClosed: <RiArrowDownSFill />,
        iconOpen: <RiArrowUpSFill />,
        subNav: manTrackBalancesSubNavs,
      };
      d.push(manTrackBalanceMenu);

      /* NEXO */
      const nexoSubNavs = [
        {
          title: 'Create',
          path: '/nexo/create',
          icon: <BsPlusSquare />,
        },
      ];
      nexoSubNavs.push.apply(nexoSubNavs, nexoAccs);
      const nexoMenu = {
        title: 'Nexo',
        path: '#',
        icon: <BiCoinStack />,
        iconClosed: <RiArrowDownSFill />,
        iconOpen: <RiArrowUpSFill />,
        subNav: nexoSubNavs,
      };
      d.push(nexoMenu);

      /* SUBSTRATE */
      const substrateSubNavs = [
        {
          title: 'Create',
          path: '/substrate/create',
          icon: <BsPlusSquare />,
        },
      ];
      substrateSubNavs.push.apply(substrateSubNavs, subAdds);
      const substrateMenu = {
        title: 'Substrate',
        path: '#',
        icon: <BiCoinStack />,
        iconClosed: <RiArrowDownSFill />,
        iconOpen: <RiArrowUpSFill />,
        subNav: substrateSubNavs,
      };
      d.push(substrateMenu);

      setData(d);
    }

    getData();
  }, [celAccs, nexoAccs, subAdds, manTrackBalances]);

  return { data };
}

export default Sidebar;
