import React, { useEffect } from 'react';
import { useAuthStore, useAplloClientStore } from '../../stores';

const Logout = () => {
  const setAuthState = useAuthStore(state => state.setAuthState);
  const resetClient = useAplloClientStore(state => state.resetClient);

  useEffect(() => {
    localStorage.removeItem('token');
    resetClient();
    setAuthState('unauthenticated');
  }, [resetClient, setAuthState]);

  return <div>Logout</div>;
};

export default Logout;
