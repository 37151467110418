import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { format } from 'date-fns';
import { manualTrackBalanceTxs } from '../../queries/manualTrackBalanceTx';
import Button from '../../styledComponents/Button';
import {
  Table,
  TableHeaderCell,
  TableBodyRow,
  TableBodyCell,
} from '../../styledComponents/Table';
import DeleteTx from './DeleteTx';

const ButtonWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TxsList = ({ balance_id }) => {
  const [showTable, setShowTable] = useState(true);
  const { data, loading } = useQuery(manualTrackBalanceTxs, {
    variables: { balance_id },
  });

  // console.log({ data, loading });

  function toggleShowTable() {
    setShowTable(prev => !prev);
  }

  if (loading) {
    return <div>loading ...</div>;
  }

  if (!(data?.manualTrackBalanceTxs?.length > 0)) {
    return <div>no data found</div>;
  }

  return (
    <div>
      <h2>Rewards List</h2>
      <ButtonWrapper>
        <Button onClick={toggleShowTable}>Show/Hide</Button>
      </ButtonWrapper>
      {showTable ? (
        <RewardsTable manualTrackBalanceTxs={data.manualTrackBalanceTxs} />
      ) : null}
    </div>
  );
};

const RewardsTable = ({ manualTrackBalanceTxs }) => {
  return (
    <Table>
      <thead>
        <tr>
          <TableHeaderCell>txdate</TableHeaderCell>
          <TableHeaderCell>txtype</TableHeaderCell>
          <TableHeaderCell>balance</TableHeaderCell>
          <TableHeaderCell>reward</TableHeaderCell>
          <TableHeaderCell>reward_usd</TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
        </tr>
      </thead>

      <tbody>
        {manualTrackBalanceTxs.map(tx => (
          <TableBodyRow key={tx.id}>
            <TableBodyCell>
              {format(tx.txdate, 'yyyy-MM-dd HH:mm')}
            </TableBodyCell>
            <TableBodyCell>{tx.txtype}</TableBodyCell>
            <TableBodyCell>{tx.balance}</TableBodyCell>
            <TableBodyCell>{tx.reward}</TableBodyCell>
            <TableBodyCell>{tx.reward_usd}</TableBodyCell>
            <TableBodyCell>
              <DeleteTx manualtrackbalancetx_id={tx.id} />
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </tbody>
    </Table>
  );
};

export default TxsList;
