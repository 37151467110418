import styled from 'styled-components';

export default styled.button`
  border: 3px solid #f70476;
  padding: 10px 20px;
  background: transparent;
  color: white;
  cursor: pointer;

  &:hover {
    color: #fbff00;
    font-weight: bold;
  }
`;
