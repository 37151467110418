import { gql } from '@apollo/client';

export const nexoAccounts = gql`
  query nexoAccounts {
    nexoAccounts {
      id
      name
    }
  }
`;

export const nexoAccount = gql`
  query nexoAccount($id: ID!) {
    nexoAccount(id: $id) {
      id
      name
    }
  }
`;

export const nexoAccountCreateMut = gql`
  mutation nexoAccountCreate($name: String!) {
    nexoAccountCreate(name: $name)
  }
`;
