import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import MainNavbar from '../MainNavbar/MainNavbar';
import Sidebar from '../Sidebar/Sidebar';
import Dashboard from '../Dashboard/Dashboard';
import Celsius from '../Celsius/Celsius';
import ManualRewardUsd from '../ManualRewardUsd/ManualRewardUsd';
import ManualTrackBalance from '../ManualTrackBalance/ManualTrackBalance';
import Nexo from '../Nexo/Nexo';
import Substrate from '../Substrate/Substrate';
import Logout from '../Logout/Logout';

const ContentWrapper = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  padding-left: 50px;
  padding-right: 20px;
  padding-top: 20px;
`;

const Authenticated = () => {
  return (
    <>
      <MainNavbar />
      <ContentWrapper>
        <Sidebar />
        <MainContent>
          <Switch>
            <Route path='/dashboard' component={Dashboard} />
            <Route path='/celsius' component={Celsius} />
            <Route path='/manual_rew_usd' component={ManualRewardUsd} />
            <Route
              path='/manual_track_balance'
              component={ManualTrackBalance}
            />
            <Route path='/nexo' component={Nexo} />
            <Route path='/substrate' component={Substrate} />
            <Route path='/logout' component={Logout} />
            <Route render={() => <Redirect to='/dashboard' />} />
          </Switch>
        </MainContent>
      </ContentWrapper>
    </>
  );
};

export default Authenticated;
