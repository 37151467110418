import React from 'react';
import styled from 'styled-components';

const CardStyled = styled.div`
  background-color: #070707;
  border: 1px solid #f70476;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
  max-width: 350px;
  padding: 20px;
`;

const CardHeader = styled.h3`
  margin-top: 0;
  margin-bottom: 10px;
`;

const CardBody = styled.div`
  display: flex;
`;

const CardDescriptionColumn = styled.div`
  width: 150px;
`;

const SummaryCard = ({
  headline,
  sum_coins,
  sum_usd,
  sum_eur,
  sum_btc,
  sum_eth,
}) => {
  return (
    <CardStyled>
      {headline && <CardHeader>{headline}</CardHeader>}
      <CardBody>
        <CardDescriptionColumn>
          {sum_coins && <div>sum_coins</div>}
          <div>sum_usd</div>
          <div>sum_eur</div>
          <div>sum_btc</div>
          <div>sum_eth</div>
        </CardDescriptionColumn>
        <div>
          {sum_coins && <div>{sum_coins}</div>}
          <div>{sum_usd}</div>
          <div>{sum_eur}</div>
          <div>{sum_btc}</div>
          <div>{sum_eth}</div>
        </div>
      </CardBody>
    </CardStyled>
  );
};

export default SummaryCard;
