import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import RewardsCard from '../../elements/RewardsCard/RewardsCard';
import ButtonGroup from '../../elements/ButtonGroup/ButtonGroup';
import { manualTrackBalanceRewardsSum } from '../../queries/manualTrackBalance';
import styled from 'styled-components';

const DEFAULT_DAYS = 7;

const Container = styled.div`
  margin-top: 15px;
`;

// TODO migrate all RewardsSummary to element

const RewardsSummary = ({ balance_id }) => {
  const [days, setDays] = useState(DEFAULT_DAYS);
  const { data, loading } = useQuery(manualTrackBalanceRewardsSum, {
    variables: { balance_id, days },
    // fetchPolicy: 'network-only',
  });

  const buttons = [1, 3, 7, 14, 30].map(d => ({
    text: `${d}d`,
    onClick: () => setDays(d),
  }));

  // console.log({ data, loading });

  return (
    <div>
      <h2>Rewards Summary</h2>
      <ButtonGroup buttons={buttons} initialActive={2} />
      <Container>
        {loading ? (
          <div>loading ...</div>
        ) : (
          <>
            {data?.manualTrackBalanceRewardsSum ? (
              <RewardsCard
                sum_coins={data.manualTrackBalanceRewardsSum.sum_coins}
                sum_usd={data.manualTrackBalanceRewardsSum.sum_usd}
                sum_eur={data.manualTrackBalanceRewardsSum.sum_eur}
                sum_btc={data.manualTrackBalanceRewardsSum.sum_btc}
                sum_eth={data.manualTrackBalanceRewardsSum.sum_eth}
              />
            ) : (
              <div>no data found</div>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default RewardsSummary;
