import { gql } from '@apollo/client';

export const summaryTotal = gql`
  query summaryTotal($days: Int) {
    summaryTotal(days: $days) {
      name
      sum_usd
      sum_eur
      sum_btc
      sum_eth
    }
  }
`;
