import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import CreateNexoAccount from './CreateNexoAccount/CreateNexoAccount';
import NexoAccount from '../NexoAccount/NexoAccount';

const Nexo = () => {
  let { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${path}/create`}>
          <CreateNexoAccount />
        </Route>

        <Route path={`${path}/:id`}>
          <NexoAccount />
        </Route>
      </Switch>
    </div>
  );
};

export default Nexo;
