import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  padding: 20px;
  background-color: #2b2b2b;
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    // console.log({ active, payload, label });
    return (
      <TooltipContainer>
        <div>{`${label}`}</div>
        {payload.map(pl => (
          <div key={pl.dataKey}>{`${pl.dataKey}: ${pl.value}`}</div>
        ))}
      </TooltipContainer>
    );
  }

  return null;
};

const MyBarChart = ({ data, secondCurr }) => {
  if (!(data?.length > 0)) {
    return <div>No data to display BarChart</div>;
  }

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        width={800}
        height={500}
        data={data}
        margin={{
          top: 5,
          right: 20,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray='3 3' />
        <YAxis yAxisId='right' orientation='right' stroke='#82ca9d' />
        <XAxis dataKey='time_unit' />
        <Tooltip content={<CustomTooltip />} />
        <Legend />

        {data[0]['sum_coins'] && (
          <YAxis yAxisId='left' orientation='left' stroke='#f70476' />
        )}
        {data[0]['sum_coins'] && (
          <Bar yAxisId='left' dataKey='sum_coins' fill='#f70476' />
        )}

        <Bar yAxisId='right' dataKey={secondCurr} fill='#82ca9d' />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MyBarChart;
