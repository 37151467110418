import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { nexoAccount } from '../../queries/nexoAccount';
import { nexoTxUploadCsvMut } from '../../queries/nexoTx';
import UploadCSV from '../../elements/UploadCSV/UploadCSV';
import NexoTxsList from './NexoTxsList';
import RewardsSummary from './RewardsSummary';
import RewardsChart from './RewardsChart';

const NexoAccount = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(nexoAccount, {
    variables: { id },
  });
  const [mutate] = useMutation(nexoTxUploadCsvMut);

  // console.log({ data, loading });

  async function uploadCb(file) {
    await mutate({
      variables: { nexo_account_id: id, file },
    });
  }

  if (loading) {
    return <div>loading ...</div>;
  }

  if (!loading && !data.nexoAccount) {
    return <div>no account found</div>;
  }

  return (
    <div>
      <h1>Nexo Account {data.nexoAccount.name}</h1>

      <UploadCSV uploadCb={uploadCb} />

      <RewardsSummary nexo_account_id={id} />

      <RewardsChart nexo_account_id={id} time_unit='month' chartType='bar' />

      <NexoTxsList nexo_account_id={data.nexoAccount.id} />
    </div>
  );
};

export default NexoAccount;
