import styled from 'styled-components';

export const HeaderRow = styled.div`
  display: flex;
  align-items: baseline;

  h3 {
    margin-left: 10px;
  }
`;
