import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { manualTrackBalance } from '../../queries/manualTrackBalance';
import { HeaderRow } from './BalancePage.styles';
import RewardsSummary from './RewardsSummary';
import TxsList from './TxsList';
import ManualTrackBalanceTxCreate from '../ManualTrackBalanceTxCreate/ManualTrackBalanceTxCreate';

const BalancePage = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(manualTrackBalance, {
    variables: { id },
  });

  // console.log({ data, loading });

  if (loading) {
    return <div>loading ...</div>;
  }

  if (!loading && !data.manualTrackBalance) {
    return <div>no tracked balance found</div>;
  }

  return (
    <div>
      <HeaderRow>
        <h1>{data.manualTrackBalance.name}</h1>
        <h3>(Manually Tracked)</h3>
      </HeaderRow>

      <ManualTrackBalanceTxCreate />

      <RewardsSummary balance_id={id} />

      <TxsList balance_id={id} />
    </div>
  );
};

export default BalancePage;
