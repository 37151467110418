import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import CreateSubstrateAddress from './CreateSubstrateAddress/CreateSubstrateAddress';
import SubstrateAddress from '../SubstrateAddress/SubstrateAddress';

const Substrate = () => {
  let { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${path}/create`}>
          <CreateSubstrateAddress />
        </Route>

        <Route path={`${path}/:id`}>
          <SubstrateAddress />
        </Route>
      </Switch>
    </div>
  );
};

export default Substrate;
