import { gql } from '@apollo/client';

export const celsiusAccounts = gql`
  query celsiusAccounts {
    celsiusAccounts {
      id
      name
    }
  }
`;

export const celsiusAccount = gql`
  query celsiusAccount($id: ID!) {
    celsiusAccount(id: $id) {
      id
      name
    }
  }
`;

export const celsiusAccountCreateMut = gql`
  mutation celsiusAccountCreate($name: String!) {
    celsiusAccountCreate(name: $name)
  }
`;
