import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import ManualTrackBalanceCreate from './ManualTrackBalanceCreate';
import BalancePage from './BalancePage';

const ManualTrackBalance = () => {
  const { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${path}/create`}>
          <ManualTrackBalanceCreate />
        </Route>

        <Route path={`${path}/:id`}>
          <BalancePage />
        </Route>
      </Switch>
    </div>
  );
};

export default ManualTrackBalance;
