import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  /* align-items: center; */
  /* justify-content: flex-start; */
  /* position: fixed; */
  /* top: 0; */
  /* left: ${({ visible }) => (visible ? '0' : '-100%')}; */
  background: #131313;
  /* background-color: antiquewhite; */
  width: 280px;
  min-height: calc(100vh - 60px);
  /* transition: 350ms; */
  /* z-index: 10; */
  border-right: 1px solid rgba(247, 4, 117, 0.212);
`;

export const SidebarWrap = styled.div`
  width: 100%;
  /* background: red; */
`;
