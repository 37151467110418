import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { celsiusAccount } from '../../queries/celsiusAccount';
import { celsiusTxUploadCsvMut } from '../../queries/celsiusTx';
import UploadCSV from '../../elements/UploadCSV/UploadCSV';
import CelsiusTxsList from './CelsiusTxsList';
import RewardsChart from './RewardsChart';

const CelsiusAccount = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(celsiusAccount, {
    variables: { id },
  });
  const [mutate] = useMutation(celsiusTxUploadCsvMut);

  // console.log({ data, loading });

  async function uploadCb(file) {
    await mutate({
      variables: { celsiusaccount_id: id, file },
    });
  }

  if (loading) {
    return <div>loading ...</div>;
  }

  if (!loading && !data.celsiusAccount) {
    return <div>no account found</div>;
  }

  return (
    <div>
      <h1>Celsius Account {data.celsiusAccount.name}</h1>

      <UploadCSV uploadCb={uploadCb} />

      <RewardsChart celsiusaccount_id={id} time_unit='day' />

      <CelsiusTxsList celsiusaccount_id={data.celsiusAccount.id} />
    </div>
  );
};

export default CelsiusAccount;
