import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../elements/Modal/Modal';
import Button from '../../styledComponents/Button';

const ButtonRow = styled.div`
  margin-top: 10px;
`;

const UploadCSV = ({ uploadCb }) => {
  const [showModal, setShowModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  async function onChange({
    target: {
      validity,
      files: [file],
    },
  }) {
    try {
      if (validity.valid) {
        // console.log(file);
        setIsUploading(true);
        // await sleep(1000);
        await uploadCb(file);
        setIsUploading(false);
        setShowModal(false);
      }
    } catch (error) {
      console.log(JSON.stringify(error, 0, 2));
    }
  }

  function handleOpenModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <div>
      <Button onClick={handleOpenModal}>Upload CSV</Button>

      <Modal isOpen={showModal}>
        {isUploading ? (
          <div>Uploading ...</div>
        ) : (
          <>
            <div>
              <input type='file' required onChange={onChange} />
            </div>
            <ButtonRow>
              <Button onClick={handleCloseModal}>Cancel</Button>
            </ButtonRow>
          </>
        )}
      </Modal>
    </div>
  );
};

export default UploadCSV;
