import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation, gql } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { handleFormError } from '../../utils/handleFormError';
import Modal from '../../elements/Modal/Modal';
import Button from '../../styledComponents/Button';
import FormErrorHintsBox from '../../elements/FormErrorHintsBox/FormErrorHintsBox';
import 'react-datepicker/dist/react-datepicker.css';

const ButtonRow = styled.div`
  margin-top: 10px;
`;

const ModalContainer = styled.div`
  height: 500px;
  width: 500px;
`;

const txTypes = ['CHECKPOINT', 'DEPOSIT', 'WITHDRAW'];

const manualTrackBalanceTxCreateMut = gql`
  mutation manualTrackBalanceTxCreate(
    $manualtrackbalance_id: ID!
    $txdate: Date!
    $balance: Float!
    $txtype: ManualTrackBalanceTxType!
  ) {
    manualTrackBalanceTxCreate(
      manualtrackbalance_id: $manualtrackbalance_id
      txdate: $txdate
      balance: $balance
      txtype: $txtype
    )
  }
`;

const ManualTrackBalanceTxCreate = () => {
  const { id } = useParams();
  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [createTx, { loading }] = useMutation(manualTrackBalanceTxCreateMut);
  const [showModal, setShowModal] = useState(false);

  function handleOpenModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  async function onSubmit({ balance, txtype, txdate }) {
    // console.log({ balance, txtype, txdate });
    try {
      await createTx({
        variables: {
          manualtrackbalance_id: id,
          balance: Number(balance),
          txtype,
          txdate,
        },
        refetchQueries: ['manualTrackBalanceTxs'],
      });
      setShowModal(false);
    } catch (error) {
      handleFormError(error, setError);
    }
  }

  return (
    <div>
      <Button onClick={handleOpenModal}>Create TX</Button>

      <Modal isOpen={showModal}>
        <ModalContainer>
          <h2>Create TX</h2>

          <FormErrorHintsBox errors={errors} />

          <form onSubmit={handleSubmit(onSubmit)}>
            <input {...register('balance')} placeholder='balance' />

            <Controller
              control={control}
              name='txdate'
              render={({ field }) => (
                <DatePicker
                  showTimeSelect
                  placeholderText='Select date'
                  onChange={date => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />

            <select {...register('txtype')}>
              {txTypes.map(t => (
                <option key={t} value={t}>
                  {t}
                </option>
              ))}
            </select>

            <button disabled={loading} type='submit'>
              Create
            </button>
          </form>

          <ButtonRow>
            <Button onClick={handleCloseModal}>Cancel</Button>
          </ButtonRow>
        </ModalContainer>
      </Modal>
    </div>
  );
};

export default ManualTrackBalanceTxCreate;
