import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useAuthStore, useAplloClientStore } from '../../stores';
import { extractGraphqlError } from '../../utils/extractGraphQlError';

const SIGNIN_MUTATION = gql`
  mutation signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      token
    }
  }
`;

const Login = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const setAuthState = useAuthStore(state => state.setAuthState);
  const resetClient = useAplloClientStore(state => state.resetClient);
  const [signin, { loading }] = useMutation(SIGNIN_MUTATION);

  async function onSubmit({ email, password }) {
    try {
      const { data } = await signin({
        variables: {
          email,
          password,
        },
      });
      localStorage.setItem('token', data.signin.token);
      resetClient();
      setAuthState('authenticated');
    } catch (error) {
      const errorCode = extractGraphqlError(error);
      if (errorCode === 'unknown-user') {
        setError('email', {
          type: 'manual',
          message: 'Unknown user.',
        });
      }
      if (errorCode === 'wrong-password') {
        setError('password', {
          type: 'manual',
          message: 'Password wrong.',
        });
      }
    }
  }

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* register your input into the hook by invoking the "register" function */}
        <input {...register('email')} />
        {errors.email && <span>{errors.email.message}</span>}

        {/* include validation with required or other standard HTML validation rules */}
        <input type='password' {...register('password')} />
        {/* errors will return when field validation fails  */}
        {errors.password && <span>{errors.password.message}</span>}

        <button disabled={loading} type='submit'>
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
