import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  nexoAccountCreateMut,
  nexoAccounts,
} from '../../../queries/nexoAccount';
import { handleFormError } from '../../../utils/handleFormError';
import FormErrorHintsBox from '../../../elements/FormErrorHintsBox/FormErrorHintsBox';

const CreateNexoAccount = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [createAccount, { loading }] = useMutation(nexoAccountCreateMut);

  async function onSubmit({ name }) {
    try {
      await createAccount({
        variables: { name },
        refetchQueries: [{ query: nexoAccounts }],
      });
    } catch (error) {
      handleFormError(error, setError);
    }
  }

  // const onError = (errors, e) => console.log(errors, e);
  // console.log(errors);

  return (
    <div>
      <h2>Create Nexo Account</h2>

      <FormErrorHintsBox errors={errors} />

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* register your input into the hook by invoking the "register" function */}
        <input {...register('name')} />
        <button disabled={loading} type='submit'>
          Create
        </button>
      </form>
    </div>
  );
};

export default CreateNexoAccount;
