import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import RewardsCard from '../../elements/RewardsCard/RewardsCard';
import ButtonGroup from '../../elements/ButtonGroup/ButtonGroup';
import { manualRewardsUsd } from '../../queries/manualReward';
import styled from 'styled-components';

const DEFAULT_DAYS = 30;

const CardContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
`;

const ManualRewardUsd = () => {
  const [days, setDays] = useState(DEFAULT_DAYS);
  const { data, loading } = useQuery(manualRewardsUsd, {
    variables: { days },
    // fetchPolicy: 'network-only',
  });

  const buttons = [1, 3, 7, 14, 30].map(d => ({
    text: `${d}d`,
    onClick: () => setDays(d),
  }));

  // console.log({ data, loading });

  return (
    <div>
      <h1>Manual Rewards USD</h1>

      <ButtonGroup buttons={buttons} initialActive={4} />

      {loading ? (
        <div>loading ...</div>
      ) : (
        <CardContainer>
          {data?.manualRewardsUsd?.length > 0 ? (
            data.manualRewardsUsd.map(mr => (
              <RewardsCard
                key={mr.id}
                headline={mr.name}
                sum_usd={mr.sum_usd}
                sum_eur={mr.sum_eur}
                sum_btc={mr.sum_btc}
                sum_eth={mr.sum_eth}
              />
            ))
          ) : (
            <div>no data found</div>
          )}
        </CardContainer>
      )}
    </div>
  );
};

export default ManualRewardUsd;
