import React, { useState } from 'react';
import { SidebarLink, SidebarLabel, DropdownLink } from './SubMenu.styles';

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const toggleSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && toggleSubnav}>
        <div>
          {item.icon}

          <SidebarLabel>{item.title}</SidebarLabel>
        </div>

        <div>
          {item.subNav && subnav
            ? item.iconOpen
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>

      {subnav && item.subNav?.map?.length > 0
        ? item.subNav.map((item, idx) => {
            return (
              <DropdownLink key={idx} to={item.path}>
                {item.icon}
                <SidebarLabel>{item.title}</SidebarLabel>
              </DropdownLink>
            );
          })
        : null}
    </>
  );
};

export default SubMenu;
