import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { format } from 'date-fns';
import { celsiusTxs, celsiusTxSetCheckedMut } from '../../queries/celsiusTx';
import Button from '../../styledComponents/Button';
import {
  Table,
  TableHeaderCell,
  TableBodyRow,
  TableBodyCell,
} from '../../styledComponents/Table';

const ButtonWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CelsiusTxsList = ({ celsiusaccount_id }) => {
  const [showTable, setShowTable] = useState(true);
  const { data, loading } = useQuery(celsiusTxs, {
    variables: { celsiusaccount_id },
    // fetchPolicy: 'network-only',
  });

  // console.log({ data, loading, error });

  function toggleShowTable() {
    setShowTable(prev => !prev);
  }

  if (loading) {
    return <div>loading ...</div>;
  }

  if (!(data?.celsiusTxs?.length > 0)) {
    return <div>no data found</div>;
  }

  return (
    <div>
      <h2>Celsius Txs Interest List</h2>
      <ButtonWrapper>
        <Button onClick={toggleShowTable}>Show/Hide</Button>
      </ButtonWrapper>
      {showTable ? <CelsiusTxsTable celsiusTxs={data.celsiusTxs} /> : null}
    </div>
  );
};

const CelsiusTxsTable = ({ celsiusTxs }) => {
  const [setChecked, { loading }] = useMutation(celsiusTxSetCheckedMut);

  async function handleCheckedChange(checked, id) {
    try {
      await setChecked({
        variables: { id, checked },
        refetchQueries: ['celsiusTxs'],
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Table>
      <thead>
        <tr>
          <TableHeaderCell>txdate</TableHeaderCell>
          <TableHeaderCell>coin_type</TableHeaderCell>
          <TableHeaderCell>amount</TableHeaderCell>
          <TableHeaderCell>usd_value</TableHeaderCell>
          <TableHeaderCell>original_coin</TableHeaderCell>
          <TableHeaderCell>amount_original</TableHeaderCell>
          <TableHeaderCell>checked</TableHeaderCell>
        </tr>
      </thead>

      <tbody>
        {celsiusTxs.map(tx => (
          <TableBodyRow key={tx.id} checked={tx.checked}>
            <TableBodyCell>
              {format(tx.txdate, 'yyyy-MM-dd HH:mm')}
            </TableBodyCell>
            <TableBodyCell>{tx.coin_type}</TableBodyCell>
            <TableBodyCell>{tx.amount.toFixed(12)}</TableBodyCell>
            <TableBodyCell>{tx.usd_value.toFixed(8)}</TableBodyCell>
            <TableBodyCell>{tx.original_coin}</TableBodyCell>
            <TableBodyCell>{tx.amount_original}</TableBodyCell>
            <TableBodyCell>
              <input
                disabled={loading}
                name='checked'
                type='checkbox'
                checked={tx.checked}
                onChange={() => handleCheckedChange(!tx.checked, tx.id)}
              />
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </tbody>
    </Table>
  );
};

export default CelsiusTxsList;
