import { gql } from '@apollo/client';

export const manualTrackBalanceTxs = gql`
  query manualTrackBalanceTxs($balance_id: ID!) {
    manualTrackBalanceTxs(balance_id: $balance_id) {
      id
      txdate
      txtype
      balance
      reward
      reward_usd
    }
  }
`;
