import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import CreateCelsiusAccount from './CreateCelsiusAccount/CreateCelsiusAccount';
import CelsiusAccount from '../CelsiusAccount/CelsiusAccount';

const Celsius = () => {
  let { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${path}/create`}>
          <CreateCelsiusAccount />
        </Route>

        <Route path={`${path}/:id`}>
          <CelsiusAccount />
        </Route>
      </Switch>
    </div>
  );
};

export default Celsius;
