import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { format } from 'date-fns';
import {
  substrateRewards,
  substrateRewardSetCheckedMut,
} from '../../queries/substrateReward';
import Button from '../../styledComponents/Button';
import {
  Table,
  TableHeaderCell,
  TableBodyRow,
  TableBodyCell,
} from '../../styledComponents/Table';

const ButtonWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const RewardsList = ({ address_id }) => {
  const [showTable, setShowTable] = useState(true);
  const { data, loading } = useQuery(substrateRewards, {
    variables: { address_id },
    // fetchPolicy: 'network-only',
  });

  // console.log({ data, loading });

  function toggleShowTable() {
    setShowTable(prev => !prev);
  }

  if (loading) {
    return <div>loading ...</div>;
  }

  if (!(data?.substrateRewards?.length > 0)) {
    return <div>no data found</div>;
  }

  return (
    <div>
      <h2>Rewards List</h2>
      <ButtonWrapper>
        <Button onClick={toggleShowTable}>Show/Hide</Button>
      </ButtonWrapper>
      {showTable ? (
        <RewardsTable substrateRewards={data.substrateRewards} />
      ) : null}
    </div>
  );
};

const RewardsTable = ({ substrateRewards }) => {
  const [setChecked, { loading }] = useMutation(substrateRewardSetCheckedMut);

  async function handleCheckedChange(checked, id) {
    try {
      await setChecked({
        variables: { id, checked },
        refetchQueries: ['substrateRewards'],
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Table>
      <thead>
        <tr>
          <TableHeaderCell>date</TableHeaderCell>
          <TableHeaderCell>value</TableHeaderCell>
          <TableHeaderCell>event_id</TableHeaderCell>
          <TableHeaderCell>action</TableHeaderCell>
          <TableHeaderCell>checked</TableHeaderCell>
        </tr>
      </thead>

      <tbody>
        {substrateRewards.map(sr => (
          <TableBodyRow key={sr.id} checked={sr.checked}>
            <TableBodyCell>{format(sr.date, 'yyyy-MM-dd HH:mm')}</TableBodyCell>
            <TableBodyCell>{sr.value}</TableBodyCell>
            <TableBodyCell>{sr.event_id}</TableBodyCell>
            <TableBodyCell>{sr.action}</TableBodyCell>
            <TableBodyCell>
              <input
                disabled={loading}
                name='checked'
                type='checkbox'
                checked={sr.checked}
                onChange={() => handleCheckedChange(!sr.checked, sr.id)}
              />
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </tbody>
    </Table>
  );
};

export default RewardsList;
