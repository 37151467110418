import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { format } from 'date-fns';
import { nexoTxs, nexoTxSetCheckedMut } from '../../queries/nexoTx';
import Button from '../../styledComponents/Button';
import {
  Table,
  TableHeaderCell,
  TableBodyRow,
  TableBodyCell,
} from '../../styledComponents/Table';

const ButtonWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const NexoTxsList = ({ nexo_account_id }) => {
  const [showTable, setShowTable] = useState(true);
  const { data, loading } = useQuery(nexoTxs, {
    variables: { nexo_account_id },
    // fetchPolicy: 'network-only',
  });

  // console.log({ data, loading });

  function toggleShowTable() {
    setShowTable(prev => !prev);
  }

  if (loading) {
    return <div>loading ...</div>;
  }

  if (!(data?.nexoTxs?.length > 0)) {
    return <div>no data found</div>;
  }

  return (
    <div>
      <h2>Nexo Txs Interest List</h2>
      <ButtonWrapper>
        <Button onClick={toggleShowTable}>Show/Hide</Button>
      </ButtonWrapper>
      {showTable ? <NexoTxsTable nexoTxs={data.nexoTxs} /> : null}
    </div>
  );
};

const NexoTxsTable = ({ nexoTxs }) => {
  const [setChecked, { loading }] = useMutation(nexoTxSetCheckedMut);

  async function handleCheckedChange(checked, id) {
    try {
      await setChecked({
        variables: { id, checked },
        refetchQueries: ['nexoTxs'],
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Table>
      <thead>
        <tr>
          <TableHeaderCell>txdate</TableHeaderCell>
          <TableHeaderCell>amount</TableHeaderCell>
          <TableHeaderCell>currency</TableHeaderCell>
          <TableHeaderCell>txtype</TableHeaderCell>
          <TableHeaderCell>usd_value</TableHeaderCell>
          <TableHeaderCell>checked</TableHeaderCell>
        </tr>
      </thead>

      <tbody>
        {nexoTxs.map(nt => (
          <TableBodyRow key={nt.id} checked={nt.checked}>
            <TableBodyCell>
              {format(nt.txdate, 'yyyy-MM-dd HH:mm')}
            </TableBodyCell>
            <TableBodyCell>{nt.amount}</TableBodyCell>
            <TableBodyCell>{nt.currency}</TableBodyCell>
            <TableBodyCell>{nt.txtype}</TableBodyCell>
            <TableBodyCell>{nt.usd_value}</TableBodyCell>
            <TableBodyCell>
              <input
                disabled={loading}
                name='checked'
                type='checkbox'
                checked={nt.checked}
                onChange={() => handleCheckedChange(!nt.checked, nt.id)}
              />
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </tbody>
    </Table>
  );
};

export default NexoTxsList;
