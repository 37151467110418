import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { format } from 'date-fns';
import { substrRewPerTimeUnit } from '../../queries/substrateReward';
import { MyAreaChart, MyBarChart } from '../../elements/Charts';
import CurrenciesButtonGroup from '../../elements/CurrenciesButtonGroup/CurrenciesButtonGroup';

const ButtonsRow = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const ChartContainer = styled.div`
  max-width: 800px;
  height: 500px;
  /* background-color: #fff; */
`;

const RewardsChart = ({ address_id, time_unit, chartType }) => {
  const [counterCurr, setCounterCurr] = useState('sum_usd');
  const { data, loading } = useQuery(substrRewPerTimeUnit, {
    variables: { address_id, time_unit },
    // fetchPolicy: 'network-only',
  });

  // console.log({ data, loading, error });

  if (loading) {
    return <div>loading ...</div>;
  }

  if (!(data?.substrRewPerTimeUnit?.length > 0)) {
    return <div>no data found</div>;
  }

  const adujstedData = data.substrRewPerTimeUnit.map(org => ({
    ...org,
    time_unit: format(org.time_unit, 'dd.MM.yyyy'),
    // time_unit: format(org.time_unit, 'yyyy MM dd'),
  }));

  return (
    <div>
      <h3>Rewards / {time_unit}</h3>

      <ButtonsRow>
        <CurrenciesButtonGroup
          includeCoin={time_unit === 'day'}
          setCurr={setCounterCurr}
        />
      </ButtonsRow>

      {chartType === 'bar' && (
        <ChartContainer>
          <MyBarChart data={adujstedData} secondCurr={counterCurr} />
        </ChartContainer>
      )}

      {chartType === 'area' && (
        <ChartContainer>
          <MyAreaChart
            data={adujstedData}
            dataKeyArea={counterCurr}
            dataKeyX={'time_unit'}
          />
        </ChartContainer>
      )}
    </div>
  );
};

export default RewardsChart;
