import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { nexoTxRewardsSum } from '../../queries/nexoTx';
import ButtonGroup from '../../elements/ButtonGroup/ButtonGroup';
import {
  Table,
  TableHeaderCell,
  TableBodyRow,
  TableBodyCell,
} from '../../styledComponents/Table';

const DEFAULT_DAYS = 30;

const RewardsSum = ({ nexo_account_id }) => {
  const [days, setDays] = useState(DEFAULT_DAYS);
  const { data, loading } = useQuery(nexoTxRewardsSum, {
    variables: { nexo_account_id, days },
    // fetchPolicy: 'network-only',
  });

  const buttons = [7, 14, 30, 90, 365].map(d => ({
    text: `${d}d`,
    onClick: () => setDays(d),
  }));

  // console.log({ data, loading, error });

  const total = useMemo(() => {
    if (data?.nexoTxRewardsSum) {
      let sum_usd = 0;
      let sum_eur = 0;
      let sum_btc = 0;
      let sum_eth = 0;

      for (let i = 0; i < data.nexoTxRewardsSum.length; i++) {
        const e = data.nexoTxRewardsSum[i];
        sum_usd += e.sum_usd;
        sum_eur += e.sum_eur;
        sum_btc += e.sum_btc;
        sum_eth += e.sum_eth;
      }

      return {
        currency: 'TOTAL',
        sum_usd,
        sum_eur,
        sum_btc,
        sum_eth,
      };
    }
  }, [data]);

  return (
    <div>
      <h2>Rewards Summary</h2>

      <ButtonGroup buttons={buttons} initialActive={2} />

      {loading ? (
        <div>loading ...</div>
      ) : (
        <>
          {data?.nexoTxRewardsSum ? (
            <div>
              <SummaryTable rewardsSum={data.nexoTxRewardsSum} total={total} />
            </div>
          ) : (
            <div>no data found</div>
          )}
        </>
      )}
    </div>
  );
};

const TableContainer = styled.div`
  /* background-color: #070707; */
  /* border: 1px solid #f70476; */
  margin-top: 20px;
  display: inline-block;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
  /* padding: 20px; */
`;

const SummaryTable = ({ rewardsSum, total }) => {
  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <TableHeaderCell>currency</TableHeaderCell>
            <TableHeaderCell>sum_coins</TableHeaderCell>
            <TableHeaderCell>sum_usd</TableHeaderCell>
            <TableHeaderCell>sum_eur</TableHeaderCell>
            <TableHeaderCell>sum_btc</TableHeaderCell>
            <TableHeaderCell>sum_eth</TableHeaderCell>
          </tr>
        </thead>

        <tbody>
          {rewardsSum.map(
            (
              { currency, sum_coins, sum_usd, sum_eur, sum_btc, sum_eth },
              idx
            ) => (
              <TableBodyRow key={idx}>
                <TableBodyCell>{currency}</TableBodyCell>
                <TableBodyCell>{sum_coins}</TableBodyCell>
                <TableBodyCell>{sum_usd.toFixed(2)}</TableBodyCell>
                <TableBodyCell>{sum_eur.toFixed(2)}</TableBodyCell>
                <TableBodyCell>{sum_btc.toFixed(8)}</TableBodyCell>
                <TableBodyCell>{sum_eth.toFixed(8)}</TableBodyCell>
              </TableBodyRow>
            )
          )}
          <TableBodyRow>
            <TableBodyCell>{total.currency}</TableBodyCell>
            <TableBodyCell></TableBodyCell>
            <TableBodyCell>{total.sum_usd.toFixed(2)}</TableBodyCell>
            <TableBodyCell>{total.sum_eur.toFixed(2)}</TableBodyCell>
            <TableBodyCell>{total.sum_btc.toFixed(8)}</TableBodyCell>
            <TableBodyCell>{total.sum_eth.toFixed(8)}</TableBodyCell>
          </TableBodyRow>
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default RewardsSum;
