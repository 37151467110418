import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { summaryTotal } from '../../queries/summary';
import ButtonGroup from '../../elements/ButtonGroup/ButtonGroup';
import {
  Table,
  TableHeaderCell,
  TableBodyRow,
  TableBodyCell,
} from '../../styledComponents/Table';

const DEFAULT_DAYS = 30;

const SummaryTotal = () => {
  const [days, setDays] = useState(DEFAULT_DAYS);
  const { data, loading } = useQuery(summaryTotal, {
    variables: { days },
    // fetchPolicy: 'network-only',
  });

  const buttons = [7, 14, 30, 90, 365].map(d => ({
    text: `${d}d`,
    onClick: () => setDays(d),
  }));

  // console.log({ data, loading });
  return (
    <div>
      <h2>total summary</h2>

      <div>
        <ButtonGroup buttons={buttons} initialActive={2} />
      </div>

      {loading ? (
        <div>loading ...</div>
      ) : (
        <>
          {data?.summaryTotal ? (
            <SummaryTable sumTotal={data.summaryTotal} />
          ) : (
            <div>no data found</div>
          )}
        </>
      )}
    </div>
  );
};

const TableContainer = styled.div`
  /* background-color: #070707; */
  /* border: 1px solid #f70476; */
  margin-top: 20px;
  display: inline-block;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
  /* padding: 20px; */
`;

const SummaryTable = ({ sumTotal }) => {
  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <TableHeaderCell>name</TableHeaderCell>
            <TableHeaderCell>sum_usd</TableHeaderCell>
            <TableHeaderCell>sum_eur</TableHeaderCell>
            <TableHeaderCell>sum_btc</TableHeaderCell>
            <TableHeaderCell>sum_eth</TableHeaderCell>
          </tr>
        </thead>

        <tbody>
          {sumTotal.map(({ name, sum_usd, sum_eur, sum_btc, sum_eth }, idx) => (
            <TableBodyRow key={idx}>
              <TableBodyCell>{name}</TableBodyCell>
              <TableBodyCell>{sum_usd.toFixed(2)}</TableBodyCell>
              <TableBodyCell>{sum_eur.toFixed(2)}</TableBodyCell>
              <TableBodyCell>{sum_btc.toFixed(8)}</TableBodyCell>
              <TableBodyCell>{sum_eth.toFixed(8)}</TableBodyCell>
            </TableBodyRow>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default SummaryTotal;
