import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// https://www.w3schools.com/howto/howto_css_dropdown.asp

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #222;
  min-width: 160px;
  /* right: 3px; */
  /* right: ${props => props.rightPush}; */
  right: ${props => (props.rightPush ? props.rightPush + 'px' : 'unset')};
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    ${DropdownContent} {
      display: block;
    }
  }
`;

const DropdownButton = styled.button`
  /* background-color: #04aa6d; */
  cursor: pointer;
  background-color: inherit;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
`;

const StyledLink = styled(Link)`
  /* color: black; */
  display: block;
  text-decoration: none;
  padding: 12px 16px;

  &:hover {
  }
`;

const CaretContainer = styled.span`
  /* background-color: white; */
  font-size: 0.7rem;
  /* margin-bottom: 10px; */
  position: absolute;
  right: 2px;
  top: 17px;
`;

const DropdownMenu = ({ title, links, rightPush }) => {
  return (
    <Dropdown>
      <DropdownButton>
        {title}
        <CaretContainer>&#9660;</CaretContainer>
      </DropdownButton>
      <DropdownContent rightPush={rightPush}>
        {links.map(({ to, title }, idx) => (
          <StyledLink key={idx} to={to}>
            {title}
          </StyledLink>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};

export default DropdownMenu;
