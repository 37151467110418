import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SidebarLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none; // needed?? no ul/li
  height: 45px;
  text-decoration: none;
  color: #e1e9fc;
  font-size: 18px;

  &:hover {
    /* background: #252831; */
    /* border-left: 4px solid #632ce4; */
  }
`;

export const SidebarLabel = styled.span`
  margin-left: 16px;
`;

export const DropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 45px;
  text-decoration: none;
  padding-left: 2rem;
  color: #e1e9fc;
  font-size: 18px;
  background: #414757;
  background: #ffffff0f;

  &:hover {
    /* background: #632ce4; */
    cursor: pointer;
  }
`;
