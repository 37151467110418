import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation, gql } from '@apollo/client';
import Modal from '../../elements/Modal/Modal';
import Button from '../../styledComponents/Button';

const ButtonRow = styled.div`
  margin-top: 10px;
`;

const manualTrackBalanceTxDeleteMut = gql`
  mutation manualTrackBalanceTxDelete($manualtrackbalancetx_id: ID!) {
    manualTrackBalanceTxDelete(
      manualtrackbalancetx_id: $manualtrackbalancetx_id
    )
  }
`;

const DeleteTx = ({ manualtrackbalancetx_id }) => {
  const [showModal, setShowModal] = useState(false);
  const [deleteTx, { loading }] = useMutation(manualTrackBalanceTxDeleteMut);

  function handleOpenModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  async function handleDelete() {
    try {
      await deleteTx({
        variables: { manualtrackbalancetx_id },
        refetchQueries: ['manualTrackBalanceTxs'],
      });
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <button onClick={handleOpenModal}>X</button>

      <Modal isOpen={showModal}>
        {loading ? (
          <div>loading ... </div>
        ) : (
          <>
            <h2>delete tx</h2>
            <ButtonRow>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button onClick={handleDelete}>OK</Button>
            </ButtonRow>
          </>
        )}
      </Modal>
    </div>
  );
};

export default DeleteTx;
