import { gql } from '@apollo/client';

export const nexoTxs = gql`
  query nexoTxs($nexo_account_id: ID!) {
    nexoTxs(nexo_account_id: $nexo_account_id) {
      id
      nexoaccount_id
      txtype
      currency
      amount
      usd_value
      details
      outstanding_loan
      txdate
      checked
    }
  }
`;

export const nexoTxRewPerTimeUnit = gql`
  query nexoTxRewPerTimeUnit(
    $nexo_account_id: ID!
    $time_unit: NexoTxRewTimeUnit!
  ) {
    nexoTxRewPerTimeUnit(
      nexo_account_id: $nexo_account_id
      time_unit: $time_unit
    ) {
      time_unit
      sum_usd
      sum_eur
      sum_btc
      sum_eth
    }
  }
`;

export const nexoTxRewardsSum = gql`
  query nexoTxRewardsSum($nexo_account_id: ID!, $days: Int) {
    nexoTxRewardsSum(nexo_account_id: $nexo_account_id, days: $days) {
      currency
      sum_coins
      sum_usd
      sum_eur
      sum_btc
      sum_eth
    }
  }
`;

export const nexoTxUploadCsvMut = gql`
  mutation nexoTxUploadCsv($nexo_account_id: ID!, $file: Upload!) {
    nexoTxUploadCsv(nexo_account_id: $nexo_account_id, file: $file)
  }
`;

export const nexoTxSetCheckedMut = gql`
  mutation nexoTxSetChecked($id: ID!, $checked: Boolean!) {
    nexoTxSetChecked(id: $id, checked: $checked)
  }
`;
