import { gql } from '@apollo/client';

export const manualTrackBalances = gql`
  query manualTrackBalances {
    manualTrackBalances {
      id
      name
      coin_ticker
    }
  }
`;

export const manualTrackBalance = gql`
  query manualTrackBalance($id: ID!) {
    manualTrackBalance(id: $id) {
      id
      name
      coin_ticker
    }
  }
`;

export const manualTrackBalanceRewardsSum = gql`
  query manualTrackBalanceRewardsSum($balance_id: ID!, $days: Int) {
    manualTrackBalanceRewardsSum(balance_id: $balance_id, days: $days) {
      sum_coins
      sum_usd
      sum_eur
      sum_btc
      sum_eth
    }
  }
`;

export const manualTrackBalanceCreateMut = gql`
  mutation manualTrackBalanceCreate($name: String!, $coin_ticker: String!) {
    manualTrackBalanceCreate(name: $name, coin_ticker: $coin_ticker)
  }
`;
