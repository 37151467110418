import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DropdownMenu from './DropdownMenu';
import logoImg from '../../assets/money-bag.png';

const Nav = styled.nav`
  display: flex;
  /* padding: 5px 20px; */
  height: 60px;
  align-items: center;
  padding-right: 15px;
  border-bottom: 1px solid rgba(247, 4, 117, 0.212);
`;

const RightDivider = styled.div`
  margin-left: auto;
`;

const Logo = styled.img`
  margin-left: 20px;
  height: 35px;
  width: 40px;
`;

const MainNavbar = () => {
  const accountLinks = useMemo(() => {
    return [{ title: 'Logout', to: '/logout' }];
  }, []);

  return (
    <Nav>
      <Link to='/'>
        <Logo src={logoImg} />
      </Link>
      <RightDivider />
      <DropdownMenu title={'Account'} links={accountLinks} rightPush={-15} />
    </Nav>
  );
};

export default MainNavbar;
