import { gql } from '@apollo/client';

export const substrateRewards = gql`
  query substrateRewards($address_id: ID!) {
    substrateRewards(address_id: $address_id) {
      id
      event_id
      block
      extrinsic_hash
      date
      value
      action
      checked
    }
  }
`;

export const substrRewPerTimeUnit = gql`
  query substrRewPerTimeUnit($address_id: ID!, $time_unit: SubstrRewTimeUnit!) {
    substrRewPerTimeUnit(address_id: $address_id, time_unit: $time_unit) {
      time_unit
      sum_coins
      sum_usd
      sum_eur
      sum_btc
      sum_eth
    }
  }
`;

export const substrateRewardsSum = gql`
  query substrateRewardsSum($address_id: ID!, $days: Int) {
    substrateRewardsSum(address_id: $address_id, days: $days) {
      sum_coins
      sum_usd
      sum_eur
      sum_btc
      sum_eth
    }
  }
`;

export const substrateRewardUploadCsv = gql`
  mutation substrateRewardUploadCsv($address_id: ID!, $file: Upload!) {
    substrateRewardUploadCsv(address_id: $address_id, file: $file)
  }
`;

export const substrateRewardSetCheckedMut = gql`
  mutation substrateRewardSetChecked($id: ID!, $checked: Boolean!) {
    substrateRewardSetChecked(id: $id, checked: $checked)
  }
`;
