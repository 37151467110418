import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  manualTrackBalanceCreateMut,
  manualTrackBalances,
} from '../../queries/manualTrackBalance';
// import { extractGraphqlError } from '../../../utils/extractGraphQlError';

const ManualTrackBalanceCreate = () => {
  const {
    register,
    handleSubmit,
    // setError,
    formState: { errors },
  } = useForm();
  const [createCoin, { loading }] = useMutation(manualTrackBalanceCreateMut);

  async function onSubmit({ name, coin_ticker }) {
    try {
      await createCoin({
        variables: { name, coin_ticker },
        refetchQueries: [{ query: manualTrackBalances }],
      });
    } catch (error) {
      // TODO
      console.log(JSON.stringify(error, 0, 2));
      // console.log(error);
      // const errorCode = extractGraphqlError(error);
      // console.log(errorCode);
      // if (errorCode === 'unknown-user') {
      //   setError('email', {
      //     type: 'manual',
      //     message: 'Unknown user.',
      //   });
      // }
      // if (errorCode === 'wrong-password') {
      //   setError('password', {
      //     type: 'manual',
      //     message: 'Password wrong.',
      //   });
      // }
    }
  }

  return (
    <div>
      <h2>Create Manual Tracked Balance</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register('name')} />
        {errors.email && <span>{errors.email.message}</span>}

        <input {...register('coin_ticker')} />
        {errors.email && <span>{errors.email.message}</span>}

        <button disabled={loading} type='submit'>
          Create
        </button>
      </form>
    </div>
  );
};

export default ManualTrackBalanceCreate;
