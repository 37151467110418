import styled from 'styled-components';

export const Table = styled.table`
  border: solid 1px #f70476;
`;

export const TableHeaderCell = styled.th`
  border-bottom: solid 3px #f70476;
  background: #cccccc;
  color: black;
  font-weight: bold;
`;

export const TableBodyRow = styled.tr`
  background-color: ${({ checked }) => (checked ? '#1b360e' : 'unset')};
`;

export const TableBodyCell = styled.td`
  padding: 7px;
  border: solid 1px gray;
`;
