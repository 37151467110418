import { gql } from '@apollo/client';

export const substrateAddresses = gql`
  query substrateAddresses {
    substrateAddresses {
      id
      name
      coin_type
    }
  }
`;

export const substrateAddress = gql`
  query substrateAddress($id: ID!) {
    substrateAddress(id: $id) {
      id
      name
      coin_type
    }
  }
`;

export const substrateAddressCoinTypes = gql`
  query substrateAddressCoinTypes {
    __type(name: "SubstrateAddressCoinType") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const substrateAddressDeleteMut = gql`
  mutation substrateAddressDelete($address_id: ID!) {
    substrateAddressDelete(address_id: $address_id)
  }
`;
