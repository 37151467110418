import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { format } from 'date-fns';
import { MyBarChart } from '../../elements/Charts';
import CurrenciesButtonGroup from '../../elements/CurrenciesButtonGroup/CurrenciesButtonGroup';
import { nexoTxRewPerTimeUnit } from '../../queries/nexoTx';

const ButtonsRow = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const ChartContainer = styled.div`
  max-width: 800px;
  height: 500px;
  /* background-color: #fff; */
`;

const RewardsChart = ({ nexo_account_id, time_unit }) => {
  const [curr, setCurr] = useState('sum_usd');
  const { data, loading } = useQuery(nexoTxRewPerTimeUnit, {
    variables: { nexo_account_id, time_unit },
    // fetchPolicy: 'network-only',
  });

  // console.log({ data, loading, error });

  if (loading) {
    return <div>loading ...</div>;
  }

  if (!(data?.nexoTxRewPerTimeUnit?.length > 0)) {
    return <div>no data found</div>;
  }

  const adujstedData = data.nexoTxRewPerTimeUnit.map(org => ({
    ...org,
    time_unit: format(org.time_unit, 'dd.MM.yyyy'),
    // time_unit: format(org.time_unit, 'yyyy MM dd'),
  }));

  return (
    <div>
      <h3>Rewards / {time_unit}</h3>

      <ButtonsRow>
        <CurrenciesButtonGroup includeCoin={false} setCurr={setCurr} />
      </ButtonsRow>

      <ChartContainer>
        <MyBarChart data={adujstedData} secondCurr={curr} />
      </ChartContainer>
    </div>
  );
};

export default RewardsChart;
