import React from 'react';
import { Container } from './HintsBox.styles';

const HintsBox = ({ messages }) => {
  if (!(messages?.length > 0)) {
    return null;
  }

  return (
    <Container>
      <ul>
        {messages.map((msg, idx) => (
          <li key={idx}>{msg}</li>
        ))}
      </ul>
    </Container>
  );
};

export default HintsBox;
