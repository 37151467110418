import { gql } from '@apollo/client';

export const celsiusTxs = gql`
  query celsiusTxs($celsiusaccount_id: ID!) {
    celsiusTxs(celsiusaccount_id: $celsiusaccount_id) {
      id
      celsiusaccount_id
      tx_id
      txdate
      txtype
      coin_type
      amount
      usd_value
      original_coin
      amount_original
      confirmed
      checked
    }
  }
`;

export const celsiusTxRewPerTimeUnit = gql`
  query celsiusTxRewPerTimeUnit(
    $celsiusaccount_id: ID!
    $time_unit: CelsiusTxRewTimeUnit!
  ) {
    celsiusTxRewPerTimeUnit(
      celsiusaccount_id: $celsiusaccount_id
      time_unit: $time_unit
    ) {
      time_unit
      sum_usd
      sum_eur
      sum_btc
      sum_eth
    }
  }
`;

export const celsiusTxUploadCsvMut = gql`
  mutation celsiusTxUploadCsv($celsiusaccount_id: ID!, $file: Upload!) {
    celsiusTxUploadCsv(celsiusaccount_id: $celsiusaccount_id, file: $file)
  }
`;

export const celsiusTxSetCheckedMut = gql`
  mutation celsiusTxSetChecked($id: ID!, $checked: Boolean!) {
    celsiusTxSetChecked(id: $id, checked: $checked)
  }
`;
