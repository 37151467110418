import React from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#000',
    border: '10px solid #f70476',
    borderRadius: '20px',

    // borderImageSlice: 1,
    // borderImageSource: 'linear-gradient(to left, #743ad5, #d53a9d)',
  },
  overlay: {
    background: '#000000EA',
  },
};

const Modal = ({ isOpen, children, onRequestClose }) => {
  return (
    <ReactModal
      style={modalStyles}
      isOpen={isOpen}
      onRequestClose={onRequestClose}>
      <>{children}</>
    </ReactModal>
  );
};

export default Modal;
