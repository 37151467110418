import React from 'react';
import ButtonGroup from '../ButtonGroup/ButtonGroup';

const CurrenciesButtonGroup = ({ setCurr, includeCoin }) => {
  const buttons = [
    { text: 'USD', onClick: () => setCurr('sum_usd') },
    { text: 'EUR', onClick: () => setCurr('sum_eur') },
    { text: 'BTC', onClick: () => setCurr('sum_btc') },
    { text: 'ETH', onClick: () => setCurr('sum_eth') },
  ];

  if (includeCoin) {
    buttons.unshift({
      text: 'Coin',
      onClick: () => setCurr('sum_coins'),
    });
  }

  return <ButtonGroup buttons={buttons} initialActive={includeCoin ? 1 : 0} />;
};

export default CurrenciesButtonGroup;
