export function extractGraphqlError(error) {
  // console.log(error);
  let errorCode = 'unknown-error';
  if (
    error?.graphQLErrors &&
    error.graphQLErrors.length > 0 &&
    error.graphQLErrors[0].message
  ) {
    errorCode = error.graphQLErrors[0].message;
  }
  return errorCode;
}

export function extractValidationErrors(error) {
  const errors = error.graphQLErrors[0]['extensions']['inner'];
  // console.log(JSON.stringify(errors, 0, 2));
  return errors;
}
