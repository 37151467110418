import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  celsiusAccountCreateMut,
  celsiusAccounts,
} from '../../../queries/celsiusAccount';
// import { extractGraphqlError } from '../../../utils/extractGraphQlError';

// TODO merge CreateCelsiusAccount + CreateNexoAccount to 1 component
const CreateCelsiusAccount = () => {
  const {
    register,
    handleSubmit,
    // setError,
    formState: { errors },
  } = useForm();
  const [createAccount, { loading }] = useMutation(celsiusAccountCreateMut);

  async function onSubmit({ name }) {
    try {
      await createAccount({
        variables: { name },
        refetchQueries: [{ query: celsiusAccounts }],
      });
    } catch (error) {
      // TODO
      console.log(JSON.stringify(error, 0, 2));
      // console.log(error);
      // const errorCode = extractGraphqlError(error);
      // console.log(errorCode);
      // if (errorCode === 'unknown-user') {
      //   setError('email', {
      //     type: 'manual',
      //     message: 'Unknown user.',
      //   });
      // }
      // if (errorCode === 'wrong-password') {
      //   setError('password', {
      //     type: 'manual',
      //     message: 'Password wrong.',
      //   });
      // }
    }
  }

  return (
    <div>
      <h2>Create Celsius Account</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* register your input into the hook by invoking the "register" function */}
        <input {...register('name')} />
        {errors.email && <span>{errors.email.message}</span>}

        <button disabled={loading} type='submit'>
          Create
        </button>
      </form>
    </div>
  );
};

export default CreateCelsiusAccount;
