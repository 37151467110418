import React from 'react';
import Modal from '../Modal/Modal';
import Button from '../../styledComponents/Button';
import styled from 'styled-components';

const ButtonsRow = styled.div`
  margin-top: 35px;
  display: flex;
  gap: 15px;
`;

const DeleteModal = ({ onRequestClose, onDelete, onCancel }) => {
  return (
    <Modal isOpen={true} onRequestClose={onRequestClose}>
      <h2>delete</h2>

      <p>really delete? all associated data will also be deleted.</p>

      <ButtonsRow>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onDelete}>Delete</Button>
      </ButtonsRow>
    </Modal>
  );
};

export default DeleteModal;
